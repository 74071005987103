<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">View Info</h1>
    </div>
    <div class="mt-4 row mx-0" v-loading="loaders.programUser">
      <div class="col-lg-6 p-0">
        <card class="no-border-card" body-classes="px-0">
          <div slot="header" class="">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="mb-0">{{ response.programUser.name }}</h2>
              <router-link :to="{name: 'admin.program_user.edit', params: {id: programUserId}}">
                <base-button type="dark-blue" outline size="xl">
                  Edit
                </base-button>
              </router-link>
            </div>
            <div class="d-inline-block mt-3">
              <p class="show-status-class px-3 py-1 mb-0"
                 :class="getStatusClass(response.programUser.status)">
                {{ getStatus(response.programUser.status) }}
              </p>
            </div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">User Name</div>
            <div class="col-6">{{ response.programUser.username }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Password</div>
            <div class="col-6">{{ response.programUser.password1 }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Email</div>
            <div class="col-6">{{ response.programUser.email }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Role</div>
            <div class="col-6">{{ response.programUser.role | programUserRole }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Created On</div>
            <div class="col-6">{{ response.programUser.created | getFormattedDate }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Modified On</div>
            <div class="col-6">{{ response.programUser.modified | getFormattedDate }}</div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import moment from "moment";
import {constants} from "@/util/constants";

export default {
  name: "ShowProgramUser",
  components: {
    BackButton
  },
  data() {
    return {
      loaders: {
        programUser: false
      },
      response: {
        programUser: {...this.$store.getters['ProgramUserModule/getProgramUser']}
      },
      programUserId: this.$route.params.id,
    }
  },
  filters: {
    /**
     * Reformat Date
     * @param date
     * @returns {string}
     */
    getFormattedDate: function (date) {
      return moment.utc(date).local().format('DD-MMM-YYYY')
    },

    /**
     * Get Program User Role
     * @param role
     * @returns {string}
     */
    programUserRole: function (role) {
      return constants.programUser.getRole[role] ?? ''
    },
  },
  mounted() {
    this.getProgramUser()
  },
  methods: {
    /**
     * Get card status
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Get status class
     * @param status
     * @returns {string}
     */
    getStatusClass(status) {
      return status === 0 ? 'inactive-status-background' : 'active-status-background'
    },

    /**
     * Get Program User
     */
    getProgramUser() {
      let vm = this
      vm.loaders.programUser = true
      const payload = {
        id: vm.programUserId,
      }
      vm.$store.dispatch('ProgramUserModule/_getRequestedProgramUser', payload)
        .then(response => {
          vm.response.programUser = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Program User',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.programUser = false
        })
    },
  }
}
</script>

<style scoped>

</style>
